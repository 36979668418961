/* eslint-disable react/prop-types */
import React from "react";
import { BlackCrossIcon } from "../../../Icons";
import styles from "./FullText.module.css";


const FullText = ({ text, closeModal }: {
	text: string;
	closeModal: () => void;
}) => {
	return (
		<div className="modal-content">
			<div className="header">
				<div>
					<p className="account-title-black">Bio</p>
				</div>
				<div className={styles.cross}>
					<img src={BlackCrossIcon} alt="cross" onClick={closeModal} />
				</div>
			</div>
			<div className="content">
				<p style={{ color: "var(--body-text-on-white)" }}>{text}</p>
			</div>
		</div>
	);
};

export default FullText;