import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "react-loader-spinner";
import useMyProfile from "hooks/useMyProfile";
import useMentor from "hooks/useMentor";
import useFavourites from "hooks/mentee/useFavourites";
import ContentList from "./components/ContentList";
import Banner from "./components/Banner";
import AvailabilityTabs from "./components/AvailabilityTabs";
import style from "./Profile.module.css";
import { createDispatchfullTextModal } from "store/actions";
import ProfileHeader from "./components/Header";
import ProfileIntroduction from "./components/Introduction";
import ProfileVideo from "./components/Video";
import Review from "./components/Reviews";
import CopyToClipboard from "components/Atoms/CopyToClipboard";
import { useAppDispatch } from "hooks/redux";

const Profile: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { profile_slug } = useParams();

  const { profile: user_profile } = useMyProfile();
  const {
    favourites,
    toggle,
    is_loading: loading_favourites,
  } = useFavourites();
  
  const { mentor: profile, is_loading: loading_profile } = useMentor({
    slug: profile_slug as string,
  });

  const is_favourite = !!(
    user_profile &&
    profile &&
    profile._id &&
    favourites &&
    favourites.includes(profile._id)
  );

  const is_preview = !!(
    profile &&
    user_profile &&
    profile._id === user_profile._id
  );

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const openVideo = () => {
    dispatch({
      type: "SET_MODAL",
      payload: "profile-video",
      data: profile?.profileVideo.url,
    });
  };

  const toggleFavourite = () => !!profile && toggle(profile?._id);

  const bookSession = () =>
    profile && navigate(`/book-session/${profile.slug}`);

  return !loading_profile && profile ? (
    <>
      <ProfileHeader
        name={`${profile.given_name} ${profile.family_name}`}
        preview={is_preview}
      />
      <div data-testid="Profile" style={{ width: "100%" }}>
        {profile.type === "mentor" && <Banner pitch={profile.pitch} />}
        <div className="content-container-profile">
          <div className="profile-container">
            <div className={style.sectionSeparator}>
              <ProfileIntroduction
                profile={profile}
                bookSession={bookSession}
                is_favourite={is_favourite}
                loading_favourites={loading_favourites}
                toggleFavourite={toggleFavourite}
                preview={is_preview}
              />
            </div>

            {profile.profileVideo && profile.profileVideo.url && (
              <div className={style.sectionSeparator}>
                <ProfileVideo
                  profile_name={profile.given_name}
                  playVideo={openVideo}
                />
              </div>
            )}

            <div className={style.profileAbout}>
              {profile.type == "mentor" && (
                <div className="profile-about-section">
                  <AvailabilityTabs availability={profile.availability} />
                </div>
              )}
              {!!profile?.reviews && profile.reviews.length > 0 && (
                <div className="profile-about-section">
                  <div className="profile-about-content">
                    <h3>Reviews</h3>
                    <ul
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      }}
                    >
                      {profile?.reviews
                        .slice()
                        .sort(
                          (a, b) =>
                            ((b?.comment && b?.comment.length) || 1) -
                            ((a?.comment && a?.comment.length) || 1)
                        )
                        .map((rev, i) => (
                          <Review key={`review-${i}`} {...rev} />
                        ))}
                    </ul>
                  </div>
                </div>
              )}
              {profile.highlights && profile.highlights.length > 0 && (
                <div className="profile-about-section">
                  <div className="profile-about-content">
                    <h3>Experience</h3>
                    <ul>
                      {profile.highlights.map((exp, i) => (
                        <ContentList
                          key={`testId-${i}`}
                          title={exp.title}
                          body={exp.description}
                        />
                      ))}
                    </ul>
                  </div>
                </div>
              )}
              {profile.content && profile.content.length > 0 && (
                <div className="profile-about-section">
                  <div className="profile-about-content">
                    <h3>Publications</h3>
                    <ul>
                      {profile.content.map((cnt, i) => (
                        <ContentList
                          key={`testId-${i}`}
                          title={cnt.title ?? ""}
                          body={cnt.description ?? ""}
                          url={cnt.url}
                        />
                      ))}
                    </ul>
                  </div>
                </div>
              )}
              <div className={style.clipboard}>
                <CopyToClipboard
                  title="Copy Profile Link"
                  value={`https://mentorpass.co/me/${profile.slug}`}
                  style={{
                    margin: "52px auto",
                    width: "fit-content",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <Grid
      data-testid="Loading"
      wrapperStyle={{ margin: "auto", display: "block", marginTop: "10%" }}
      wrapperClass={style.spinner}
      color="var(--primary-colour)"
      height={42}
      width={42}
    />
  );
};

export default Profile;
