import { useState } from "react";
import { Grid } from "react-loader-spinner";

import useMentorSessions, {
  MentorSessions,
  SesssionViewStatus,
} from "hooks/mentor/useMentorSessions";

import TabHeader from "components/Molecules/SessionsTabHeader";

import style from "./MentorSessions.module.css";
import UpcomingSession from "./UpcomingSession";
import RequestedSession from "./RequestedSession";
import PastSession from "./PastSession";

const makeSessionsOptions = (
  sessions: MentorSessions
): {
  id: SesssionViewStatus;
  title: string;
  alert_quantity: number;
}[] => {
  return [
    {
      id: SesssionViewStatus.REQUEST,
      title: "Request",
      alert_quantity: sessions[SesssionViewStatus.REQUEST].length,
    },
    {
      id: SesssionViewStatus.UPCOMING,
      title: "Upcoming",
      alert_quantity: sessions[SesssionViewStatus.UPCOMING].length,
    },
    {
      id: SesssionViewStatus.PAST,
      title: "Past",
      alert_quantity: sessions[SesssionViewStatus.PAST].length,
    },
  ];
};

const MentorSessionsDashboard: React.FC = () => {
  const [active_tab, setTab] = useState<SesssionViewStatus>(
    SesssionViewStatus.UPCOMING
  );

  const { sessions, is_loading } = useMentorSessions();

  return (
    <div data-testid="MentorSessions" className={style.mySessions}>
      <div>
        <TabHeader
          active_tab={active_tab}
          // @ts-ignore
          onTabChange={setTab}
          options={makeSessionsOptions(sessions)}
        />
        <div className={style.sessions_list}>
          {is_loading ? (
            <div>
              <Grid
                wrapperStyle={{
                  margin: "auto",
                  display: "block",
                  maginTop: "4px",
                }}
                color="#5440F0"
                height={42}
                width={42}
              />
            </div>
          ) : (
            <div className={style.no_sessions}>
              {sessions[active_tab].length
                ? sessions[active_tab].map((session) => {
                    if (active_tab == SesssionViewStatus.REQUEST) {
                      return (
                        <RequestedSession key={session._id} session={session} />
                      );
                    }
                    if (active_tab === SesssionViewStatus.UPCOMING) {
                      return (
                        <UpcomingSession key={session._id} session={session} />
                      );
                    }
                    if (active_tab === SesssionViewStatus.PAST) {
                      return (
                        <PastSession key={session._id} session={session} />
                      );
                    }
                  })
                : "No sessions"}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MentorSessionsDashboard;
