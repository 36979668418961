import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import useMyProfile from "./useMyProfile";
import { API_URL, createHeaders } from "utils/api";
import { Video } from "components/Molecules/VideoCard";
import { ca } from "date-fns/locale";

const useSessionRecordings = (): [Video[] | undefined, boolean] => {
  const { getAccessTokenSilently } = useAuth0();
  const [videos, setVideos] = useState<Video[]>();
  const [is_loading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | undefined>();

  const { type } = useMyProfile();

  useEffect(() => {
    const getVideos = () => {
      setIsLoading(true);
      getAccessTokenSilently().then((token) =>
        fetch(`${API_URL}/session/recording/${type}/all`, {
          method: "GET",
          mode: "cors",
          headers: createHeaders(token),
        })
          .then((response) => response.json())
          .then((videos: Video[]) => {
            setVideos(videos.sort((va, bv) => bv.created_at - va.created_at));
          })
          .catch((error) => {
            console.error(error);
            setError(error);
          })
          .finally(() => setIsLoading(false))
      );
    };
    getVideos();
  }, [type]);

  return [videos, is_loading];
};

export default useSessionRecordings;
