import React, { useState } from "react";
import moment from "moment";
import Styles from "./MenteeSessions.module.css";
import {
  UpBlueChevron,
  BlueChevron,
  CalendarIcon,
  CancelCircle,
  TickSign,
  DollarIcon,
} from "../../Icons";
import AvatarPlaceholder from "../../../images/avatar-placeholder.gif";
import { ALLOWED_STATUS } from "entities/Session";
import { useAlert } from "@blaumaus/react-alert";
import { useNavigate } from "react-router-dom";
import { MenteeSessionQueryResult } from "entities/MenteeSession";
import TimeSlotSelector from "../../Molecules/TimeSlotSelector/TimeSlotSelector";
import useMyProfile from "hooks/useMyProfile";
import useAcceptSession from "hooks/mentor/session/useAcceptSession";
import useDeclineSession from "hooks/mentor/session/useDeclineSession";
import { ThreeDots } from "react-loader-spinner";

interface SessionCardProps {
  session: MenteeSessionQueryResult;
}

import dayjs from "utils/datetime";

interface ProposedTimesProps {
  proposed_times: string[];
  timezone: string;
}

const ProposedTimes: React.FC<ProposedTimesProps> = ({
  proposed_times,
  timezone,
}) => {
  return (
    <div className={Styles.proposed_times}>
      <h4>Your proposed times:</h4>
      <ul>
        {proposed_times.map((time, index) => (
          <li key={index}>
            {dayjs(time).tz(timezone).format("ddd, MMM D, h:mm A z")}
          </li>
        ))}
      </ul>
    </div>
  );
};

const MenteeSessionRequestCard = ({ session }: SessionCardProps) => {
  const [show_details, setshow_details] = useState(false);
  const navigate = useNavigate();
  const { profile } = useMyProfile();
  const { accept, is_loading: accept_session_loading } = useAcceptSession();
  const { decline, is_loading: decline_session_loading } = useDeclineSession();

  const handleAcceptSession = async (slot: string): Promise<void> => {
    accept(session._id, slot, () => {
      window.location.reload();
    });
  };

  const handleDeclineSession = async () => {
    decline(session._id, "", () => {
      window.location.reload();
    });
  };

  const handleRescheduleSession = async () => {
    navigate(`/reschedule/${session._id}`);
  };

  const toggleDetails = () => setshow_details(!show_details);

  const formatSessionDuration = (duration: number) => {
    if (!duration) return "";
    return {
      30: "30 min session",
      60: "1 hour session",
    }[duration];
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.card}>
        <div className={Styles.mentor_info_container}>
          <img
            className={Styles.avatar}
            alt="avatar"
            src={session.mentor_profile?.profileImage?.url || AvatarPlaceholder}
          />
          <div className={Styles.mentor_info}>
            <h1>{`${session.mentor_profile.given_name} ${session.mentor_profile.family_name}`}</h1>
            <div className={Styles.session_details}>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="16"
                  viewBox="0 0 14 16"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M4 0C3.44772 0 3 0.447715 3 1V2H2C0.895431 2 0 2.89543 0 4V5V7V14C0 15.1046 0.89543 16 2 16H12C13.1046 16 14 15.1046 14 14V7V5V4C14 2.89543 13.1046 2 12 2H11V1C11 0.447715 10.5523 0 10 0C9.44771 0 9 0.447715 9 1V2H5V1C5 0.447715 4.55228 0 4 0ZM12 7H2L2 14H12V7ZM9 9H5V12H9V9Z"
                    fill="#5440F0"
                  />
                </svg>
                <p className={Styles.session_details_text}>
                  {moment(session.ideal_proposed_time).format("llll")} -{" "}
                  {formatSessionDuration(session.duration)}
                </p>
              </div>
            </div>
          </div>
        </div>
        <button className={Styles.details_button} onClick={toggleDetails}>
          Details
          <img
            width="15px"
            height="15px"
            src={UpBlueChevron}
            alt="up-blue-chevron"
            className={Styles.chevron}
            style={{
              transform: show_details ? "rotate(0deg)" : "rotate(180deg)",
            }}
          />
        </button>
      </div>
      {show_details && (
        <div className={Styles.details_container}>
          <div>
            <div className={Styles.agenda}>
              <h4>Agenda</h4>
              <p>{session.description}</p>
            </div>
          </div>
          {session.proposed_by == "mentor" &&
            session.status === ALLOWED_STATUS.RESCHEDULE && (
              <div className={Styles.requested_session_action_container}>
                <h3>Reschedule Request</h3>
                <p className={Styles.reschedule_message}>
                  Your mentor has requested to reschedule this session. Please
                  choose a new date from the options below:
                </p>
                <TimeSlotSelector
                  timezone={profile.timezone}
                  inital_slot={session.ideal_proposed_time}
                  slots={session.proposed_times}
                  note={session.reschedule_note}
                  accept_session_loading={accept_session_loading}
                  confirmSession={handleAcceptSession}
                  decline_session_loading={decline_session_loading}
                  handleRescheduleSession={handleRescheduleSession}
                  handleDeclineSession={handleDeclineSession}
                />
              </div>
            )}
          {session.status === ALLOWED_STATUS.RESCHEDULE &&
            session.proposed_by === "mentee" && (
              <div className={Styles.reschedule_status}>
                <p className={Styles.reschedule_message}>
                  Your reschedule request has been sent. We're waiting for a
                  response from your mentor.
                </p>
                <ProposedTimes
                  proposed_times={session.proposed_times}
                  timezone={profile.timezone}
                />
              </div>
            )}
          {[ALLOWED_STATUS.REQUEST].includes(session.status) && (
            <div>
              <ProposedTimes
                proposed_times={session.proposed_times}
                timezone={profile.timezone}
              />
              <div className={Styles.action_buttons}>
                <button
                  onClick={handleRescheduleSession}
                  className={Styles.black_button}
                >
                  Propose other times
                </button>
                <button
                  onClick={handleDeclineSession}
                  className={Styles.red_button}
                >
                  {" "}
                  {decline_session_loading ? (
                    <ThreeDots
                      // style={{ marginRight: "2px" }}
                      color="#FFF"
                      height={18}
                      width={24}
                    />
                  ) : (
                    "Decline session"
                  )}
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MenteeSessionRequestCard;
