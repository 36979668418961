import moment from "moment";
import classNames from "classnames/bind";

import styles from "./VideoCard.module.css";

export interface Video {
  _id: string;
  session_id: string;
  video_id: string;
  created_at: number;
  session_date: string;
  ready: string;
  title: string;
  description: string;
  thumbnail_mini: string;
}

interface VideoCardProps {
  launchVideo: (video_id: string) => void;
  video: Video;
}

const cx = classNames.bind(styles);

const VideoCard: React.FC<VideoCardProps> = ({
  launchVideo,
  video: { _id, title, ready, session_date, thumbnail_mini },
}) => {
  const handleLaunch = () => {
    if (!ready) return;

    launchVideo(_id);
  };

  const containerStyles = cx({
    container: true,
    ready: !!ready,
  });

  return (
    <div
      data-testid="VideoCard"
      className={containerStyles}
      onClick={handleLaunch}
    >
      {thumbnail_mini && ready ? (
        <img
          className={styles.video_thumbnail}
          src={
            thumbnail_mini ||
            "https://feetfirst.org/wp-content/uploads/2020/08/placeholder-16_9.jpg"
          }
        />
      ) : (
        <div className={styles.video_thumbnail}>
          <p>Processing...</p>
        </div>
      )}
      <div className={styles.metadata}>
        <h3>{title}</h3>
        <p>{moment(session_date).format("lll")}</p>
      </div>
    </div>
  );
};

export default VideoCard;
